import React, { Component, ReactNode, RefObject } from "react"
import Style from "./ticker.style"
import "./ticker.style.css"
import { MOBILE_MAX_WIDTH } from "../../styles"

let componentTickerSpeed = 0.5

interface Props {
  tickerSpeed?: number // In case we wish to change the default speed
  showNextPrevArrows?: boolean
  lightTheme?: boolean
  manual?: boolean
  paused?: boolean
}

interface State {
  isPaused: boolean
  slideshow: any
  flickity: any
  slideshowNumbers: number
}

class Ticker extends Component<Props, State> {
  state = {
    isPaused: false,
    slideshow: [],
    flickity: [],
    slideshowNumbers: 0,
  }

  update = () => {
    const { isPaused, slideshowNumbers, flickity } = this.state
    const { manual } = this.props
    if (
      typeof window === "undefined" ||
      window.innerWidth <= MOBILE_MAX_WIDTH ||
      manual ||
      isPaused
    )
      return
    if (slideshowNumbers && slideshowNumbers > 0) {
      for (let i = 0; i < slideshowNumbers; i++) {
        if (flickity && flickity[i] && flickity[i].slides) {
          flickity[i].x =
            (flickity[i].x - componentTickerSpeed) % flickity[i].slideableWidth
          flickity[i].selectedIndex = flickity[i].dragEndRestingSelect()
          flickity[i].updateSelectedSlide()
          flickity[i].settle(flickity[i].x)
        }
      }
    }
    window.requestAnimationFrame(this.update)
  }

  pause = () => {
    this.setState({ isPaused: true })
  }

  play = () => {
    const { isPaused } = this.state
    if (isPaused && typeof window !== "undefined") {
      this.setState({ isPaused: false })
      window.requestAnimationFrame(this.update)
    }
  }

  componentDidMount(): void {
    const {
      tickerSpeed,
      showNextPrevArrows,
      lightTheme,
      manual,
      children,
    } = this.props
    const theme = lightTheme ? "light" : "dark"
    if (tickerSpeed !== undefined) componentTickerSpeed = tickerSpeed
    const tmpSlideShow = document.querySelectorAll(`.CustomSlideshow-${theme}`)
    const links = document.querySelectorAll("#ctaId")
    this.setState({ slideshow: tmpSlideShow })
    this.setState({ slideshowNumbers: tmpSlideShow && tmpSlideShow.length })
    if (typeof window !== "undefined" && tmpSlideShow) {
      const isMobile = window.innerWidth <= MOBILE_MAX_WIDTH || manual
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const Flickity = require("flickity")
      const flickityTmp = []
      for (let i = 0; i < tmpSlideShow.length; i++) {
        flickityTmp[i] =
          tmpSlideShow &&
          Flickity &&
          new Flickity(tmpSlideShow[i], {
            autoPlay: false,
            draggable: !(children && children?.length < 2),
            wrapAround: true,
            freeScroll: !isMobile,
            pageDots: isMobile,
            prevNextButtons: showNextPrevArrows || false,
            selectedAttraction: 0.015,
            friction: 0.25,
          })
      }
      for (let i = 0; i < links.length; i++) {
        if (!(children && children?.length < 2)) {
          links[i]?.addEventListener("mouseenter", this.pause, false)
          links[i]?.addEventListener("focusin", this.pause, false)
          links[i]?.addEventListener("mouseleave", this.play, false)
          links[i]?.addEventListener("focusout", this.play, false)
        }
      }
      if (!(children && children?.length < 2)) {
        this.update()
      }
      this.setState({ flickity: flickityTmp })
    }
  }

  render(): ReactNode {
    const { children, lightTheme } = this.props
    const theme = lightTheme ? "light" : "dark"
    if (typeof window === "undefined") return null
    return (
      <Style.Container className={theme}>
        <div
          className={`CustomSlideshow-${theme}`}
          style={{ width: "100%", outline: "none", height: "100%" }}
        >
          {children}
        </div>
      </Style.Container>
    )
  }
}

export default Ticker
