import styled, { css } from "styled-components"
import Img from "gatsby-image"
import { MOBILE_MAX_WIDTH } from "../../styles"

const DefaultHighlightStyle = css`
  position: absolute;
  z-index: 2;
  &:hover {
    background-color: white;
    opacity: 0.2;
  }
  @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
    display: none;
  }
`

export default {
  Container: styled.div`
    position: relative;
    min-height: fit-content;

    height: inherit;
    overflow: hidden;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding-top: 0rem;
    }
  `,

  TopCarouselImages: {
    TickerImage: styled(Img)`
      width: calc(100% - 110px);
      height: 100%;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        width: calc(100% - 53px);
        height: 100%;
        object-fit: cover;
      }
    `,
    HighlightSpan: styled.div`
      ${DefaultHighlightStyle}
      z-index: 2;
      width: calc(100% - 110px);
    `,
  },
  BottomCarouselImages: {
    TickerImage: styled(Img)`
      width: calc(100% - 110px);
      height: 380px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        width: calc(100% - 76px);
        height: 375px;
      }
    `,
    HighlightSpan: styled.div`
      ${DefaultHighlightStyle}
      z-index: 2;
      width: calc(100% - 110px);
      height: 380px;
    `,
  },
}
